import { gql } from '@apollo/client';
import React from 'react';
import { DividerPumoModuleFragment } from '../../generated/types';
import { RestrictPlatform } from '../RestrictPlatform';
import { DividerModule } from './DividerModule';

export const DIVIDER_PUMO_MODULE_FRAGMENT = gql`
  fragment DividerPumoModuleFragment on DividerPumoModule {
    id
    layoutType
    moduleType
    modelId
    text
    name
    verticalPadding
    platforms: platform
  }
`;

export interface Props {
  dividerPumoModule: DividerPumoModuleFragment;
}

export const DividerPumoModule: React.FC<Props> = ({ dividerPumoModule }) => {
  const { layoutType, text, verticalPadding, platforms, modelId, moduleType } = dividerPumoModule;

  return (
    <RestrictPlatform platforms={platforms}>
      <DividerModule
        layoutType={layoutType}
        text={text}
        verticalPadding={verticalPadding}
        modelId={modelId}
        moduleType={moduleType}
      />
    </RestrictPlatform>
  );
};
