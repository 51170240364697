import { gql } from '@apollo/client';
import React from 'react';
import { MultiPumoModuleFragment } from '../../generated/types';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import { NormalizePumoStyles } from '../NormalizePumoStyles';
import { RestrictPlatform } from '../RestrictPlatform';
import { PumoModuleSlide, PUMO_MODULE_SLIDE_FRAGMENT } from '../PumoModuleSlide';
import { EDITORIAL_BG_COLORS, EditorialBgColor } from '../PumoModuleEditorialText';

import './MultiPumoModule.scss';

interface Props {
  multiPumoModule: MultiPumoModuleFragment;
}

export const MULTI_PUMO_MODULE_FRAGMENT = gql`
  fragment MultiPumoModuleFragment on MultiPumoModule {
    name
    bgColor
    platforms: platform
    units {
      ...PumoModuleSlideFragment
    }
    moduleType
    modelId
  }
  ${PUMO_MODULE_SLIDE_FRAGMENT}
`;

export const MultiPumoModule: React.FC<Props> = ({
  multiPumoModule: { units, bgColor, platforms, modelId, moduleType, name }
}) => {
  const { ref } = useModuleTracking({ name });

  const backgroundColor = EDITORIAL_BG_COLORS[bgColor as EditorialBgColor];

  return (
    <RestrictPlatform platforms={platforms}>
      <div ref={ref} className="MultiPumoModuleWrapper">
        <NormalizePumoStyles
          className={`MultiPumoModule MultiPumoModule--${units.length}`}
          style={{ backgroundColor }}
          data-model-id={modelId}
          data-module-type={moduleType}
        >
          {units.map((slide, index) => (
            <PumoModuleSlide key={index} index={index + 1} slide={slide} />
          ))}
        </NormalizePumoStyles>
      </div>
    </RestrictPlatform>
  );
};
