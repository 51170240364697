import { Button, ControlLink, Input, LoadingBalls, Stack, Text } from '@moda/om';
import React from 'react';
import { gql } from '@apollo/client';
import { useFeatureFlag } from '@moda/portal-stanchions';
import { hrefFor } from '../../routers';
import { SmsPumoModuleFragment } from '../../generated/types';
import { RestrictPlatform } from '../RestrictPlatform';
import { PhoneNumberTermsAndConditions } from '../PhoneNumberTermsAndConditions';
import { useSmsPumoModule } from './useSmsPumoModule';
import {
  SmsPumoModuleSlides,
  SMS_PUMO_MODULE_SLIDES_FRAGMENT
} from './SmsPumoModuleSlides/SmsPumoModuleSlides';

import './SmsPumoModule.scss';

export const SMS_PUMO_MODULE_FRAGMENT = gql`
  fragment SmsPumoModuleFragment on SmsPumoModule {
    name
    headlineText: headline_text
    bodyText: body_text
    platforms: platform
    ...SmsPumoModuleSlidesFragment
  }
  ${SMS_PUMO_MODULE_SLIDES_FRAGMENT}
`;

interface Props {
  smsPumoModule: SmsPumoModuleFragment;
}

export const SmsPumoModule: React.FC<Props> = ({ smsPumoModule }) => {
  const {
    isSubscribed,
    handleSubmit,
    error,
    handleChangePhone,
    phone,
    handleChangeEmail,
    phoneNumberValidationError,
    mailValidationError,
    email,
    loading
  } = useSmsPumoModule();

  const { headlineText, bodyText, platforms } = smsPumoModule;

  // The feature flag is a number, but we'll want a string to display.
  const welcomeOffer = useFeatureFlag('configuration-set-welcome-offer', [
    { default: '15' },
    { getFromTreatment: treatment => String(treatment) ?? '15' }
  ]);

  return (
    <RestrictPlatform platforms={platforms}>
      <div className="SmsPumoModule">
        <div className="SmsPumoModule__slides">
          <SmsPumoModuleSlides smsPumoModule={smsPumoModule} />
        </div>

        <Stack className="SmsPumoModule__content" space={4}>
          {!isSubscribed && (
            <>
              <Text
                className="SmsPumoModule__title"
                family="serif"
                treatment="h3"
                color="cream"
                dangerouslySetInnerHTML={{ __html: headlineText }}
              />

              <Stack className="SmsPumoModule__body-text" alignItems="center" space={2}>
                <Text treatment="h6" color="cream" dangerouslySetInnerHTML={{ __html: bodyText }} />
              </Stack>

              <form className="SmsPumoModule__form" onSubmit={handleSubmit}>
                <Stack alignItems="center" className="SmsPumoModule__form-content" space={3}>
                  <Input
                    className="SmsPumoModule__input"
                    label="E-Mail **"
                    name="email"
                    disabled={isSubscribed}
                    placeholder="E-mail Address"
                    type="email"
                    error={mailValidationError}
                    required
                    value={email}
                    onChange={handleChangeEmail}
                    color="cream"
                  />

                  <Input
                    className="SmsPumoModule__input"
                    label="U.S. Mobile Number †"
                    name="phone"
                    error={phoneNumberValidationError}
                    disabled={isSubscribed}
                    placeholder="Mobile Number"
                    type="phone"
                    value={phone}
                    onChange={handleChangePhone}
                  />

                  <PhoneNumberTermsAndConditions color="snow" />

                  <Text className="SmsPumoModule__email-legal" treatment="body2" color="cream">
                    ** Email required
                  </Text>

                  {error && <Text color="code-red">We are unable to process this request</Text>}

                  {loading && <LoadingBalls className="SmsPumoModule__loading" />}

                  <Button
                    className="SmsPumoModule__call-to-action"
                    disabled={loading || isSubscribed}
                    type="submit"
                    role="button"
                    tertiary
                  >
                    SIGN UP
                  </Button>

                  <Stack alignItems="center" space={2}>
                    <Text treatment="body2" color="cream">
                      * Terms and Conditions Apply
                    </Text>

                    <Text treatment="body2" color="cream">
                      View{' '}
                      <ControlLink
                        to={hrefFor.TermsAndConditionsPage()}
                        className="SmsPumoModule__footer-link"
                        underlined
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Text treatment="body2" color="cream">
                          Terms
                        </Text>
                      </ControlLink>{' '}
                      &amp;{' '}
                      <ControlLink
                        to={hrefFor.PrivacyPolicyPage()}
                        className="SmsPumoModule__footer-link"
                        underlined
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Text treatment="body2" color="cream">
                          Privacy Policy
                        </Text>
                      </ControlLink>
                    </Text>
                  </Stack>
                </Stack>
              </form>
            </>
          )}

          {isSubscribed && (
            <Stack space={4}>
              <Text color="cream" className="SmsPumoModule__title" family="serif" treatment="h3">
                You're on the list.
              </Text>

              <Text color="cream" className="SmsPumoModule__title" family="serif" treatment="h4">
                Thanks for signing up! Now check your messages - your{' '}
                <span className="SmsPumoModule__discount">{welcomeOffer}% off</span> is waiting for
                you.
              </Text>
            </Stack>
          )}
        </Stack>
      </div>
    </RestrictPlatform>
  );
};
