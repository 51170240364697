import React from 'react';
import { useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import { CarouselPumoModuleFragment } from '../../generated/types';
import { hrefFor } from '../../routers';
import { useModuleTracking } from '../../hooks/useModuleTracking';
import {
  CarouselPumoModuleSlider,
  CAROUSEL_PUMO_MODULE_SLIDER_FRAGMENT
} from '../CarouselPumoModuleSlider';
import {
  CarouselPumoModuleGrid,
  CAROUSEL_PUMO_MODULE_GRID_FRAGMENT
} from '../CarouselPumoModuleGrid';
import { CarouselPumoModuleCategories } from '../CarouselPumoModuleCategories';
import { RestrictPlatform } from '../RestrictPlatform';

export const CAROUSEL_PUMO_MODULE_FRAGMENT = gql`
  fragment CarouselPumoModuleFragment on CarouselPumoModule {
    name
    id
    layoutType
    modelId
    platform
    ...CarouselPumoModuleSliderFragment
    ...CarouselPumoModuleGridFragment
  }
  ${CAROUSEL_PUMO_MODULE_SLIDER_FRAGMENT}
  ${CAROUSEL_PUMO_MODULE_GRID_FRAGMENT}
`;

enum LayoutType {
  Carousel = 1,
  Grid = 2
}

interface Props {
  carouselPumoModule: CarouselPumoModuleFragment;
}

export const CarouselPumoModule: React.FC<Props> = ({ carouselPumoModule }) => {
  const { pathname } = useLocation();
  const { ref, onClick } = useModuleTracking({
    name: carouselPumoModule.name,
    attachClickHandler: false,
    variants: carouselPumoModule.cells
  });

  /**
   * This is a temporary workaround for getting the category tiles styled correctly on the Beauty Landing Page.
   * TODO: Implement proper support for this in our CMS.
   */
  if (
    (pathname === hrefFor.BeautyLandingPage() ||
      pathname === hrefFor.HomeLandingPage() ||
      pathname === hrefFor.EditorialPage({ slug: 'beauty-test-page' })) &&
    carouselPumoModule.name === 'Categories'
  )
    return (
      <RestrictPlatform platforms={carouselPumoModule.platform}>
        <div ref={ref} className="CarouselPumoModule">
          <CarouselPumoModuleCategories carouselPumoModule={carouselPumoModule} onClick={onClick} />
        </div>
      </RestrictPlatform>
    );

  if (carouselPumoModule.layoutType === LayoutType.Grid) {
    return (
      <RestrictPlatform platforms={carouselPumoModule.platform}>
        <div ref={ref} className="CarouselPumoModule">
          <CarouselPumoModuleGrid carouselPumoModuleGrid={carouselPumoModule} onClick={onClick} />
        </div>
      </RestrictPlatform>
    );
  }

  return (
    <RestrictPlatform platforms={carouselPumoModule.platform}>
      <div ref={ref} className="CarouselPumoModule">
        <CarouselPumoModuleSlider
          carouselPumoModuleSlider={carouselPumoModule}
          moduleName={carouselPumoModule.name}
          onClick={onClick}
        />
      </div>
    </RestrictPlatform>
  );
};
