import { differenceInMilliseconds, isAfter } from 'date-fns';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTimer } from '@moda/portal-stanchions';
import { CallToAction } from '../CallToAction';
import { TimeSection } from './TimeSection';

import './ModuleTrunkshowCountdown.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  startDate: number;
  endDate: number;
  slug: string;
  name: string;
}

const shouldShowCountdown = (endDate: number): boolean => {
  return differenceInMilliseconds(endDate, new Date()) > 0;
};

export const ModuleTrunkshowCountdown: React.FC<Props> = ({
  name,
  slug,
  endDate,
  startDate,
  className
}) => {
  const showCountdown = shouldShowCountdown(endDate);
  const [timeLeft, setEndDate] = useTimer(startDate, true);

  useEffect(() => {
    timeLeft.isOver && timeLeft.currentEndDate === startDate ? setEndDate(endDate) : false;
  }, [endDate, startDate, setEndDate, timeLeft.currentEndDate, timeLeft.isOver]);

  const { daysLeft, hoursLeft, minutesLeft, secondsLeft } = timeLeft;
  return (
    <div className={classNames('ModuleTrunkshowCountdown', className)}>
      {showCountdown && (
        <div>
          <div className="ModuleTrunkshowCountdown__intro">
            {isAfter(startDate, new Date()) ? 'STARTING IN' : 'ENDING IN'}
          </div>
          <div className="ModuleTrunkshowCountdown__clock">
            <TimeSection amountToShow={daysLeft} units={daysLeft === '01' ? 'DAY' : 'DAYS'} />
            <TimeSection amountToShow={hoursLeft} units={hoursLeft === '01' ? 'HOUR' : 'HOURS'} />
            <TimeSection amountToShow={minutesLeft} units="MINS" />
            <TimeSection amountToShow={secondsLeft} units="SECS" />
          </div>

          <CallToAction href={`/${slug}`}>
            {isAfter(startDate, new Date()) ? 'SIGN UP NOW' : 'PREORDER NOW'}
          </CallToAction>
        </div>
      )}

      {!showCountdown && (
        <div>
          <CallToAction href={`/${slug}`}>{name} HAS ENDED</CallToAction>
        </div>
      )}
    </div>
  );
};
