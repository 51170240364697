import { space, breakpoints, remToUnitlessPx } from '@moda/tokens';
import { SwiperCarouselProps } from './SwiperCarousel';

export const CAROUSEL_DEFAULT_CONFIG: Partial<SwiperCarouselProps> = {
  className: 'SwiperCarousel--multiple-slides-per-view',
  spaceBetween: remToUnitlessPx(space.map['three-quarter-x']),
  slidesPerView: 2,
  slidesPerGroup: 2,
  breakpoints: {
    [remToUnitlessPx(breakpoints.xs)]: {
      slidesPerView: 3,
      slidesPerGroup: 3
    },
    [remToUnitlessPx(breakpoints.sm)]: {
      slidesPerView: 4,
      slidesPerGroup: 4
    },
    [remToUnitlessPx(breakpoints.md)]: {
      slidesPerView: 5,
      slidesPerGroup: 5
    },
    [remToUnitlessPx(breakpoints.lg)]: {
      slidesPerView: 6,
      slidesPerGroup: 6
    }
  }
};
