import React from 'react';
import { Divider, NoLineDivider, TwoLineDivider, DividerProps } from '@moda/om';

export const DIVIDER_LAYOUT_TYPES = {
  ONE_LINE: 1,
  TWO_LINE: 2,
  NO_LINE: 3
};

export interface DividerModuleProps {
  layoutType: number | null;
  text: string | null;
  verticalPadding?: string | null;
  modelId?: string | null;
  moduleType?: string | null;
}

export const DividerModule: React.FC<DividerModuleProps> = ({
  layoutType,
  text,
  verticalPadding,
  modelId,
  moduleType
}) => {
  let DividerComponent: React.FC<DividerProps>;

  switch (layoutType) {
    case DIVIDER_LAYOUT_TYPES.ONE_LINE:
      DividerComponent = Divider;
      break;
    case DIVIDER_LAYOUT_TYPES.TWO_LINE:
      DividerComponent = TwoLineDivider;
      break;
    case DIVIDER_LAYOUT_TYPES.NO_LINE:
      DividerComponent = NoLineDivider;
      break;
    default:
      DividerComponent = Divider;
  }

  return (
    <div className="DividerModule" data-model-id={modelId} data-module-type={moduleType}>
      <DividerComponent text={text || ''} style={{ padding: `${verticalPadding} 0` }} />
    </div>
  );
};
