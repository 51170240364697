import React, { useRef } from 'react';
import { gql } from '@apollo/client';
import { Breakpoint, useBreakpoint } from '@moda/om';
import classNames from 'classnames';
import { useAutoScale } from '@moda/portal-stanchions';
import { PumoModuleSlideFragment } from '../../generated/types';
import {
  PumoModuleEditorialText,
  PUMO_MODULE_EDITORIAL_TEXT_FRAGMENT
} from '../PumoModuleEditorialText';
import { PUMO_MODULE_IMAGE_FRAGMENT, PumoModuleImage } from '../PumoModuleImage';
import { doesSlideHaveEditorialText } from './doesSlideHaveEditorialText';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cover?: boolean;
  slide: PumoModuleSlideFragment;
  beforeEyebrow?: React.ReactNode;
  afterEyebrow?: React.ReactNode;
  normalizePumoStyles?: boolean;
  spaceBetween?: boolean;
}

export const PUMO_MODULE_SLIDE_FRAGMENT = gql`
  fragment PumoModuleSlideFragment on Slide {
    id
    ctaUrl
    featureImage {
      ...PumoModuleImageFragment
    }
    mobileImage {
      ...PumoModuleImageFragment
    }
    slideText {
      ...PumoModuleEditorialTextFragment
    }
  }
  ${PUMO_MODULE_IMAGE_FRAGMENT}
  ${PUMO_MODULE_EDITORIAL_TEXT_FRAGMENT}
`;

export const PumoModuleSlideContent: React.FC<Props> = ({
  cover,
  slide,
  beforeEyebrow,
  afterEyebrow,
  normalizePumoStyles,
  spaceBetween,
  children
}) => {
  const slideHasEditorialText = doesSlideHaveEditorialText(slide.slideText);
  const { featureImage, mobileImage } = slide;

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { matches: isMobile } = useBreakpoint({ lt: 'md' });
  const { matches: isDesktop } = useBreakpoint({ gt: 'md' });

  const contentStyleProps = useAutoScale({
    container: containerRef.current,
    node: contentRef.current,
    skip:
      (isMobile && !isDesktop) ||
      !featureImage ||
      slide.slideText?.taglineText?.body?.includes('<video')
  });

  return (
    <>
      <Breakpoint lt="md">
        <section className="PumoModuleSlideContent">
          {mobileImage && <PumoModuleImage pumoModuleImage={mobileImage} />}
          {slideHasEditorialText && slide?.slideText && (
            <PumoModuleEditorialText
              normalizePumoStyles={normalizePumoStyles}
              spaceBetween={spaceBetween}
              beforeEyebrow={beforeEyebrow}
              afterEyebrow={afterEyebrow}
              textFragment={slide?.slideText}
              className={classNames('PumoModuleSlide__editorial', {
                'PumoModuleSlide__editorial--withImage': Boolean(mobileImage)
              })}
            >
              {children}
            </PumoModuleEditorialText>
          )}
        </section>
      </Breakpoint>

      <Breakpoint gt="md">
        <section className="PumoModuleSlideContent" ref={containerRef}>
          {featureImage && <PumoModuleImage cover={cover} pumoModuleImage={featureImage} />}
          {slideHasEditorialText && slide.slideText && (
            <PumoModuleEditorialText
              normalizePumoStyles={normalizePumoStyles}
              spaceBetween={spaceBetween}
              beforeEyebrow={beforeEyebrow}
              afterEyebrow={afterEyebrow}
              textFragment={slide.slideText}
              className={classNames('PumoModuleSlide__editorial', {
                'PumoModuleSlide__editorial--withImage': Boolean(featureImage)
              })}
              ref={contentRef}
              contentStyle={contentStyleProps}
            >
              {children}
            </PumoModuleEditorialText>
          )}
        </section>
      </Breakpoint>
    </>
  );
};
